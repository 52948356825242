<template>
    <div>
    <div class="p-2 mt-2">
    <b-row class="" >
                        <b-col sm="12" md="12">
                            <b-overlay :show="show" rounded="sm" class="mt-5">
            <span v-for="(notify,i) in notification " :key="i">          
<h6 class="custom-text" ><span>{{ notify.carbaonTime }}</span></h6>

 <div class="card d-flex justify-content-between p-3 mt-2" v-for="(notify1,j) in  notification1[notify.log_date] " :key="j">
   
    <div>
        <b class="text-venuecolor">{{ notify1.name }} </b> - <b>{{ jsonParse(notify1.description).logs }}</b>  <span class="text-muted float-right">{{ notify1.log_time }} </span>  <br>
        {{ jsonParse(notify1.description).desc }} 
   </div>
   <div>
    
    
   </div>
</div>  

</span> 
</b-overlay>    
<!-- <div class="card d-flex justify-content-between p-3 mt-2 ">
   
    <div>
        <b>Booking Notification</b>  <span class="text-muted float-right"> 2min ago</span>  <br>
        Retarget users when they go dormant, A notification that caters their interest can be sent in let’s say on the 7th day of abandonment to bring them back to the websit
   </div>
</div>
<div class="card d-flex justify-content-between p-3 mt-2">
   
    <div>
        <b>Booking Notification</b>  <span class="text-muted float-right"> 2min ago</span>  <br>
        Retarget users when they go dormant, A notification that caters their interest can be sent in let’s say on the 7th day of abandonment to bring them back to the websit
   </div>
</div> -->
<!-- <h6 class="custom-text"><span>Yesterday</span></h6>
<div class="card d-flex justify-content-between p-3 mt-2">
   
   <div>
    <b>Booking Notification</b>  <span class="text-muted float-right"> 1 day ago</span>  <br>
       Retarget users when they go dormant, A notification that caters their interest can be sent in let’s say on the 7th day of abandonment to bring them back to the websit
  </div>
</div>      -->              
                        </b-col>
                       
                    </b-row>
                </div>

      </div>
  </template>
  
  <script>
  //import Teams from "./Teams.vue";
  import axios from "axios";
  export default {

      components:
      {
         
      },
      data() {
              return {
                show:false,
                notification:[],
                notification1:[]
                
      }
  },
  methods:{
    notifications()
    {
        axios.get("/admin/notification").then((resp) => {
            this.notification=resp.data.detail;
            this.notification1=resp.data.detail1;
            this.show=false;
        });
    },
    jsonParse(array)
    {
        return  JSON.parse(array);
    }
  },
  mounted()
  {
     this.show=true;
    this.notifications();
   // localStorage.removeItem('Profile_setup');
  }
  //editTeam
  }
  </script>
  
  <style>
 .w-10
 {
    width:30px;
 }
 .text-venuecolor
    {
        color:#DD1BFB;
        text-transform: capitalize;
    }
  </style>