<template>
    <div>
		<b-button class="mt-2 mb-2 float-right" v-b-modal.modal-sm variant="primary"  size="sm" v-if="bank_form.edit_bank_details==true">
								<i class="mdi mdi-pencil"></i></b-button>
								<br>
        <b-row>
            <b-col>
        <h6 class="custom-text mt-4 "><span>KYC Verification</span></h6>
						<div class="">
						<div class="row">
							<div class="col-md-7">
							<table class="table ">
								<thead>
									<tr>
										<th class="col-md-1"></th>
										<th class="col-md-2">Applicable</th>
										<th class="col-md-4">Number</th>
										<th class="">Status</th>
									</tr>
								</thead>
								<tbody>
									<tr class="">
										<td scope="row">PAN</td>
										<td>
											<span  class="cursor-pointer" v-bind:class="{ 'text-success' : verify_pan.pan_no != null }">
												<span v-if="verify_pan.pan_no != null">Yes</span> 
												<span v-if="verify_pan.pan_no == null" @click="kyctab(1)">Yes</span>  
											</span> |
											<span v-bind:class="{ 'text-danger' : verify_pan.pan_no === null }" >
												No
											</span>
											
											</td>
										<td>{{ verify_pan.pan_no ? verify_pan.pan_no: 'Not Added'}}</td>
										
										<td>
											<span v-if="verify_pan.pan_no==null">
												<b-badge variant="danger">Pending</b-badge>
											</span>
											<span v-else>
												<b-badge variant="success">Verified</b-badge>
											</span>
										</td>
									</tr>
									<tr class="">
										<td scope="row">GST</td>
										<td>
											<span   class="cursor-pointer" v-bind:class="{ 'text-success' : verify_pan.gst_no != null }">
												<span v-if="verify_pan.gst_no == null" @click="kyctab(1)">Yes</span> 
												<span v-if="verify_pan.gst_no != null" >Yes</span>  
											</span> |
											<span v-bind:class="{ 'text-danger' : verify_pan.gst_no === null }" >
												No
											</span>
											
										</td>
										<td>{{ verify_pan.gst_no ? verify_pan.gst_no: 'Not Added'}}</td>
										
										<td>
											<span v-if="verify_pan.gst_no==null">
												<b-badge variant="danger">Pending</b-badge>
											</span>
											<span v-else>
												<b-badge variant="success">Verified</b-badge>
											</span>
										</td>
									</tr> 
									<tr class="">
										<td scope="row">TAN</td>
										<td>
												<span   class="cursor-pointer" v-bind:class="{ 'text-success' : verify_pan.tan_no != null }" >
											
												<span v-if="verify_pan.tan_no == null" @click="kyctantab(1)">Yes</span> 
												<span v-if="verify_pan.tan_no != null" >Yes</span>  
											</span> |
											<span v-bind:class="{ 'text-danger' : verify_pan.tan_no === null }" >
												No
											</span>
											
										</td>
										<td>{{ verify_pan.tan_no ? verify_pan.tan_no: 'Not Added'}}</td>
											
										<td>
											<span v-if="verify_pan.tan_no==null">
												<b-badge variant="danger">Pending</b-badge>
											</span>
											<span v-else>
												<b-badge variant="success">Verified</b-badge>
											</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="col-md-5 ">
							<div class="card custom-card">
							<div class="row ">
								<div class="col-md-4"> 
									Legal name  : 
								</div>
								<div class="col-md-8"> 
									{{json_parse(verify_pan.gst_pan_details).legal_name}}
								</div>
								<div class="col-md-4"> 
									Pincode  : 
								</div>
								<div class="col-md-8"> 
									{{json_parse(verify_pan.gst_pan_details).pincode}}
								</div>
								<div class="col-md-4"> 
									City  : 
								</div>
								<div class="col-md-8"> 
									{{json_parse(verify_pan.gst_pan_details).city}}
								</div>
								<div class="col-md-4"> 
									State  : 
								</div>
								<div class="col-md-8"> 
									{{json_parse(verify_pan.gst_pan_details).state}}
								</div>
								<div class="col-md-4"> 
									Address  : 
								</div>
								<div class="col-md-8"> 
									{{json_parse(verify_pan.gst_pan_details).address}}
								</div>
								
							</div>
							</div>
							
						</div>
					</div>
					</div>
					</b-col>
                    <b-col sm="12">
                        <h6 class="custom-text"><span>Bank Details</span></h6>
						
						<div class="row">
							<div class="col-md-7">
								<b-row >
							<b-col cols="6" >
								<b-row class="mt-3">
									<b-col cols="4" class="mt-2">Account No</b-col>
									<b-col cols="8">
										
										<b-form-group>
											<b-form-input class="form-control venue-custom-input"
											id="c_name"
											type="text"
											placeholder="Enter account no"
											v-model="bank_form.account_no"
											></b-form-input>
											
										</b-form-group>
									</b-col>
									
								</b-row>
								<b-row class="mt-3">
									<b-col cols="4" class="mt-2">IFSC Code</b-col>
									<b-col cols="8">
										<b-form-group>
											<b-form-input class="form-control venue-custom-input"
											id="c_name"
											type="text"
											placeholder="Enter IFSC code"
											v-model="bank_form.ifsc_code"
											
											@blur="IfsccodeCheck()"
											></b-form-input>
											
										</b-form-group>
									</b-col>
									
								</b-row>
								
							</b-col>
                            <b-col cols="6" >
                                
							</b-col>
						</b-row>
                        <b-row class="mt-3">
							<b-col cols="9 " offset-md="3">
								<span v-if="this.verify_pan.account_no==null">
									<button  class="btn btn-venuecolor btn-sm btn-sm mx-auto w-50"  @click="get_bank_details()" :disabled="this.bank_form.get_detail_btn_disable">Verify </button>
								
								</span>
								<span v-else>
								

								<button v-if="bank_form.edit_bank_details==false" class="btn btn-venuecolor btn-sm btn-sm mx-auto w-50"  @click="get_bank_details()" :disabled="this.bank_form.get_detail_btn_disable">Verify </button>
								</span>
							</b-col>
						</b-row> 
							</div>
							<div class="col-md-5">
						<div class="card custom-card">
							<div class="row ">
								
								
								<div class="col-md-4"> 
									Account Holder  : 
								</div>
								<div class="col-md-7"> 
									{{ this.verify_pan.account_holder_name }}
								</div>
								<div class="col-md-1">
								
								</div>
								<div class="col-md-4"> 
									Account No  : 
								</div>
								<div class="col-md-8"> 
									{{ this.verify_pan.account_no }}
								</div>
								<div class="col-md-4"> 
									Bank name  : 
								</div>
								<div class="col-md-8"> 
									{{ this.verify_pan.bank_name }}
								</div>
								<div class="col-md-4"> 
									Branch name  : 
								</div>
								<div class="col-md-8"> 
									{{ this.verify_pan.branch_name }}
								</div>
								<div class="col-md-4"> 
									Status  : 
								</div>
								<div class="col-md-8"> 
									<span class="text-danger" v-if="this.verify_pan.account_holder_name!=json_parse(verify_pan.gst_pan_details).legal_name">
									Account Not Verified
									</span>
									<span class="text-danger" v-else-if="this.verify_pan.account_no==null">
									Verification Pending
									</span>
									<span class="text-success" v-else>
									Account Verified
									</span>
								</div>
								
							</div>
							</div>
							</div>
						</div>
			
					</b-col>
                    
				</b-row>
				<b-modal 
					id="modal-sm" 
					size="md" 
					title="ADMIN KYC "
					header-bg-variant="primary"
					header-text-variant="light"
					@ok="UpdatePan()"
					ok-title="Update"
					>
					<b-row>
								<b-col cols="6" class="mt-3">
									<b-col cols="12">
										<b-form-input class="form-control venue-custom-input"
								placeholder="Enter PAN number." v-model="form.pan">
								
								</b-form-input>
										
									</b-col>
									<b-col cols="12">
										<b-form-input class="form-control venue-custom-input"
										placeholder="Enter state"
										v-model="form.state"
										>
										</b-form-input>
										
										
									</b-col>
									<b-col cols="12">
										<b-form-input class="form-control venue-custom-input"
										placeholder="Enter pincode"
										v-model="form.pincode"
										>
										</b-form-input>
										
									</b-col>
									<b-col cols="12">
										<b-form-input class="form-control venue-custom-input"
										placeholder="Enter TAN no"
										v-model="form.tan"
										>
										</b-form-input>
									</b-col>
									
								</b-col> 
								<b-col cols="6" class="mt-3">
									<b-col cols="12">
										<b-form-input class="form-control venue-custom-input"
										placeholder="Enter GST number."
										v-model="form.gst"
										>
										</b-form-input>
										
										
									</b-col>
									<b-col cols="12">
										<b-form-input class="form-control venue-custom-input"
										placeholder="Enter Legal name."
										v-model="form.legal_name"
										>
										</b-form-input>
										
									</b-col>
									<b-col cols="12">
										<b-form-input class="form-control venue-custom-input"
										placeholder="Enter city name"
										v-model="form.city"
										>
										</b-form-input>
										
									</b-col>
									<b-col cols="12">
										<b-form-input class="form-control venue-custom-input"
										placeholder="Enter country"
										v-model="form.country"
										>
										</b-form-input>
									</b-col>
									
									
								</b-col> 
								<b-col cols="12">
									<b-col cols="12">
										<b-form-input class="form-control venue-custom-input"
										placeholder="Enter address"
										v-model="form.address"
										>
										</b-form-input>
									</b-col>
								</b-col>
								
							</b-row>
								<h6 class="custom-text mt-3"><span>Bank Details</span></h6>
								<b-row>
								<b-col cols="12" class="mt-3">
									<b-col cols="12">
										<b-form-input class="form-control venue-custom-input"
								placeholder="Enter Account number." v-model="form.accno">
								
								</b-form-input>
									</b-col>
									
								</b-col>
								<b-col cols="6" class="mt-3">
									<b-col cols="12">
										<b-form-input class="form-control venue-custom-input"
								placeholder="Enter Account Name." v-model="form.name">
								
								</b-form-input>
										
									</b-col>
									<b-col cols="12">
										<b-form-input class="form-control venue-custom-input"
										placeholder="Enter IFSC Code"
										v-model="form.ifsc"
										>
										</b-form-input>
										
										
									</b-col>
									
								</b-col> 
								<b-col cols="6" class="mt-3">
									<b-col cols="12">
										<b-form-input class="form-control venue-custom-input"
										placeholder="Enter Bank name"
										v-model="form.bankname"
										>
										</b-form-input>
										
										
									</b-col>
									<b-col cols="12">
										<b-form-input class="form-control venue-custom-input"
										placeholder="Enter Branch name"
										v-model="form.branch"
										>
										</b-form-input>
										
										
									</b-col>
									
								</b-col>
								
							</b-row>
							
				</b-modal>		
               
    </div>
  </template>
      
  <script>
  import axios from "axios";
  import { validationMixin } from "vuelidate";
  
	
  export default {
  mixins: [validationMixin],
  props: ['selectedTab'],
          data() {
              return {
                  
                verify_pan:[],
                selectedTabs: 0,
				form:{
					pan:'',
					pincode:'',
					city:'',
					gst:'',
					state:'',
					legal_name:'',
					accno : '',
					branch : '',
					bankname : '',
					ifsc : '',
					tan : '',
					name : '',
					country : '',
				},
                kyc_tab:{
					pan_number:'',
					gst_number:'',
					company_name:'',
					city:'',
					pincode:'',
					state:'',
					address:'',
					disable_get_pan : false,
					kyc_detail_form : false,
					disable_pan : false,
				},
				tan_form:[{
					tan_no:'',
					acknowledge_status:false,
					kyc_image:null
				}],
                bank_form:{
					account_no:'',
					ifsc_code:'',
					account_holder_name:'',
					edit_bank_details:false,
					get_detail_btn_disable:false,
					bank_name:'',
					branch_name:'',
				}, 
              }
          },
          methods:
          {
            json_parse(json)
			{
return json? JSON.parse(json) : "";
			}, 
          
			validateState(name) {
				const { $dirty, $error } = this.$v.tan_form[name];
				return $dirty ? !$error : null;
			},
			
			
			kyctab(checkverify)
			{
				if(checkverify==1)
				{
					this.$root.$emit("bv::show::modal", "modal-1");
				}
				
			},
			kyctantab(checkverify)
			{
				if(checkverify==1)
				{
					this.$root.$emit("bv::show::modal", "modal-2");
				}
				
			},
            getKycDetails()
			{
			
				axios
				.get("/admin/get_kyc_details")
				.then((resp) => {
					if (resp.data) {
							this.verify_pan=resp.data;
							this.bank_form.account_no=this.verify_pan.account_no
							this.bank_form.ifsc_code=this.verify_pan.ifsc_code
							this.$store.commit("SET_KYC_NOTIFICATION", resp.data.users);
							this.form.pan = resp.data.pan_no;
							this.form.pincode = JSON.parse(this.verify_pan.gst_pan_details).pincode;
							this.form.city = JSON.parse(this.verify_pan.gst_pan_details).city;
							this.form.gst = resp.data.gst_no;
							this.form.state = JSON.parse(this.verify_pan.gst_pan_details).state;
							this.form.legal_name = JSON.parse(this.verify_pan.gst_pan_details).legal_name;
							this.form.accno = resp.data.account_no;
							this.form.branch = resp.data.branch_name ;
							this.form.bankname = resp.data.bank_name;
							this.form.ifsc = resp.data.ifsc_code;
							this.form.address = JSON.parse(this.verify_pan.gst_pan_details).address;
							this.form.country = JSON.parse(this.verify_pan.gst_pan_details).country;
							this.form.tan = resp.data.tan_no;
							this.form.name = resp.data.account_holder_name;
					

							if(resp.data.account_holder_name!=null)
							{
								this.bank_form.edit_bank_details=true;
							}
							else{
								this.bank_form.edit_bank_details=false;
							}

						}
				});
			},
			UpdatePan()
			{
				axios
				.post("/admin/update_pan_details",this.form )
				.then((resp) => {
					
					this.getKycDetails();
					resp;
				});
			}
          },
              /* END password Reset function  */
          mounted() {
            this.getKycDetails();
          },
          watch:{
              
              new_password(){
              
              }
          }
      }
  
  
  </script>
  
  <style>
  ul.password_validation li
  {
  list-style-type: none;
  }
  .has_required{
      /*  text-decoration: line-through;  */
      color:#689868;
  } 
  .has_required:before {
    content: '\2713';
    display: inline-block;
    color:#689868;
    padding: 0 6px 0 0;
  }
  .Background-color-gray .list-group-item
  {
      background: #eef5f9;
  }
  </style>