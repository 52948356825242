<template>
  <div>
    <div class="d-flex justify-content-between mt-3">
				<!-- 	<div>
						<h4>Reset My password </h4>
					</div> -->
					<!-- $entity->created_at->diffForHumans(); -->
				</div> 
                <hr>
                <b-row>
                    <b-col sm="12 " >
                        <b-row>
							<b-col sm="6">
								<h6 class="custom-text"><span>Reset password</span></h6>
								<b-form @submit.prevent="handleSubmit">
									<b-row class="mt-3">
										<b-col cols="4" md="4" class="text-left">Old Password</b-col>
										<b-col cols="12" md="8">
											<b-form-group id="old_password"  >
												<b-form-input  v-bind:type="showPassword ? 'text' : 'password'"
												class="form-control venue-custom-input"  name="" id="old_password" aria-describedby="helpId" placeholder="Enter Old password"
												v-model="$v.form.old_password.$model" :state="validateStatepasword('old_password')"
												></b-form-input>
												<span v-on:click="showPassword = !showPassword" class="venue-password-hidden">
													<i v-bind:class="showPassword ? 'mdi mdi-eye' : 'mdi mdi-eye-off'" ></i>
												</span>
												
												<b-form-invalid-feedback v-if="!this.$v.form.old_password.required">
													Please enter old password
												</b-form-invalid-feedback>
											</b-form-group>
										</b-col>
										
									</b-row> 
									<b-row class="mt-3">
										<b-col cols="4" md="4" class="text-left">New Password</b-col>
										<b-col cols="12" md="8">
											<b-form-group id="new_password"  label-for="new_password">
												<b-form-input v-bind:type="showPassword1 ? 'text' : 'password'"
												onselectstart="return false" onpaste="return false;" onCopy="return false" onCut="return false" onDrag="return false" onDrop="return false" autocomplete=off
												class="form-control venue-custom-input"  name="" id="new_password" aria-describedby="helpId" placeholder="Enter New password"
												v-model="$v.form.new_password.$model" :state="validateStatepasword('new_password')"
												></b-form-input>
												<span v-on:click="showPassword1 = !showPassword1" class="venue-password-hidden">
													<i v-bind:class="showPassword1 ? 'mdi mdi-eye' : 'mdi mdi-eye-off'" ></i>
												</span>
												<b-form-invalid-feedback>
													<span v-if="!this.$v.form.new_password.required">
													Please enter new password</span>
													<span v-if="this.$v.form.new_password.required &&
													!this.$v.form.new_password.minLength
													">Password must be 8 characters long.</span>
													<span v-if="this.$v.form.new_password.required &&
													this.$v.form.new_password.minLength &&
													!this.$v.form.new_password.passwordCheck
													">Password should contain at least a-z or A-Z and number and
													special character..</span>
												</b-form-invalid-feedback>
											</b-form-group>
										</b-col>
										
									</b-row> 
									<b-row class="mt-3">
										<b-col cols="4" md="4" class="text-left">Re-enter Password</b-col>
										<b-col cols="12" md="8">
											<b-form-group id="confirm_password"  >
												<b-form-input v-bind:type="showPassword2 ? 'text' : 'password'"
												class="form-control venue-custom-input" name="" id="confirm_password" aria-describedby="helpId" placeholder="Enter Re-enter password"
												v-model="$v.form.confirm_password.$model" :state="validateStatepasword('confirm_password')"
												></b-form-input>
												<span v-on:click="showPassword2 = !showPassword2" class="venue-password-hidden">
													<i v-bind:class="showPassword2 ? 'mdi mdi-eye' : 'mdi mdi-eye-off'" ></i>
												</span>
												
												<b-form-invalid-feedback>
													<span v-if="!this.$v.form.confirm_password.required">Please confirm new password.
													</span>
													<span v-if="this.$v.form.confirm_password.required &&
													!this.$v.form.confirm_password.sameAsPassword
													">The confirm password must be same as password.
													</span>
												</b-form-invalid-feedback>
											</b-form-group>
										</b-col>
										
									</b-row> 
									<b-row class="mt-3">
										<button type="submit"  class="btn btn-venuecolor btn-sm btn-sm mx-auto w-50" >Change password</button>
									</b-row> 
								</b-form>
							</b-col> 
							<b-col sm="6">
								<h6 class="custom-text"><span>Password should contain</span></h6>
								
									<ul class="password_validation">
										
										<li><span :class="has_uppercase ? 'has_required' : ''">One uppercase letter</span></li> 
										<li><span :class="has_lowercase ? 'has_required' : ''">One lowercase letter</span></li>
										<li><span :class="has_special ? 'has_required' : ''">One special character.</span></li>
										<li><span :class="has_number ? 'has_required' : ''">One number</span></li>
										<li><span :class="has_minimum_lenth ? 'has_required' : ''">Atleast 8 chacters</span></li>
									</ul> 
								<h6 class="custom-text"><span>History</span></h6>
								<b-list-group class="Background-color-gray" v-if="history">
									<b-list-group-item
									v-for="(passhistory ,key) in  history " :key="key"
									>
									<span v-if="key==0" >Last Updated </span>
									<span v-else > Updated</span>
									{{ passhistory }}
								
								</b-list-group-item>
								
								</b-list-group>
								<b-list-group class="Background-color-gray" v-else >
									<b-list-group-item> No Data Found </b-list-group-item>
								</b-list-group>
							</b-col>
							
						</b-row>
					</b-col>
				</b-row>
  </div>
</template>
	
<script>
import axios from "axios";
import { validationMixin } from "vuelidate";

	import {
		required , helpers  ,sameAs,minLength
	} from "vuelidate/lib/validators";

    const passwordCheck = helpers.regex(
    "passwordCheck",
    /^.*(?=.{3,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\d\x])(?=.*[!@&$#%_]).*$/
	);	
export default {
mixins: [validationMixin],
		data() {
			return {
				showPassword:false,
				showPassword1:false,
				showPassword2:false,
				form: {
					old_password: '',
					new_password: '',
					confirm_password: '',
				},
				has_minimum_lenth: false,
				has_number: false,
				has_lowercase: false,
				has_uppercase: false,
				has_special: false,
				status: false,
				history:[]
				
			}
		},
		validations: {
			
			form: {
				old_password: { required },
				new_password: { required, minLength: minLength(8), passwordCheck },
				confirm_password: { required, sameAsPassword: sameAs("new_password") },
			},
		},
		methods:
		{
			
			validateStatepasword(field) {
				this.passwordCheck();
				const { $dirty, $error } = this.$v.form[field];
				return $dirty ? !$error : null;
			},
			
			/* password Reset function  */
			handleSubmit() {
				console.log("button works");
				this.$v.form.$touch();
				console.log(!this.$v.form.$anyError);
				if (!this.$v.form.$anyError) {
					this.changePassword();
				}
			},
			changePassword() {
				axios
                .post("/admin/change_password", {
                    old_password: this.form.old_password,
                    new_password: this.form.new_password,
				})
                .then((resp) => {
                    if (resp.data.status) {
                        this.$root.$refs.app.showToast("success", resp.data.message);
                      //  this.$router.push("/login");
						} else {
                        this.$root.$refs.app.showToast("danger", resp.data.message);
					}
				});
			},
			passwordCheck()
			{
				this.has_minimum_lenth = (this.form.new_password.length > 8);
				this.has_number    = /\d/.test(this.form.new_password);
				this.has_lowercase = /[a-z]/.test(this.form.new_password);
				this.has_uppercase = /[A-Z]/.test(this.form.new_password);
				this.has_special   = /[!@&$#%_]/.test(this.form.new_password);
			},
			getUserHistory() {
				
				axios.get("/admin/get_password_history").then((resp) => {
					this.history = resp.data.users;
					/* if (resp.data.status_code == 200) {
						
						if (resp.data.status) {
							this.history = resp.data.passwordLogs;
						}
					} */
				});
			},
		},
			/* END password Reset function  */
		mounted() {
			//this.getUserHistory();
		},
		watch:{
			
			new_password(){
			
			}
		}
	}


</script>

<style>
ul.password_validation li
{
list-style-type: none;
}
.has_required{
    /*  text-decoration: line-through;  */
    color:#689868;
} 
.has_required:before {
  content: '\2713';
  display: inline-block;
  color:#689868;
  padding: 0 6px 0 0;
}
.Background-color-gray .list-group-item
{
	background: #eef5f9;
}
</style>