<template>
  <div>
    <div class="d-flex justify-content-between mt-3">
      <div >
        <h3>Document</h3>
      </div>
      <div>
        <b-button   class="btn-venuecolor"  v-b-modal="'my-modal'">Add Folder</b-button>
      </div>
    </div>
    <div class=" mt-2">
        <!--   <div class="col-md-1" v-for="(folder, f) in document" :key="f"> -->
           <!--  <router-link title="View" :to="'my-customers/view/' + data.item.id"> -->
          <!--   <router-link title="View" to="my-customers/view/">
            <img src="https://png.pngtree.com/element_our/png/20181206/folder-vector-icon-png_260858.jpg" class="w-100" >
            <p>{{ folder.name }}</p>
            </router-link>
          </div> -->

        <div class="accordion" role="tablist">
    <b-card no-body class="mb-1" v-for="(folder, f) in document" :key="f">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle="'accordion-'+f" variant="light">
          <div class="flex justify-content-between">
            <div>
              <i class="mdi mdi-folder" style="color:rgb(255, 183, 0)"></i> {{ folder.name }}
            </div>
            <div>
              {{ documents[folder.id].length ==0 ? 'No' : documents[folder.id].length }} Documents 
            </div>
            <div>
              <i class="mdi mdi-dots-vertical" style="color:rgb(255, 183, 0);font-size:20px"></i>
            </div>
          </div>
        
        </b-button>
      </b-card-header>
      <b-collapse :id="'accordion-'+f" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
         
            <table
              class="table "
            >
              <thead>
                <tr>
                  <th scope="col">File name</th>
                  <th scope="col">File</th>
                  <th scope="col">Uploaded By</th>
                </tr>
              </thead>
              <tbody>
                <tr class="" v-for="(docs,d) in documents[folder.id]" :key="d">
                  <td scope="row">{{ docs.name }}</td>
                  <td><a :href="backend_url+'/'+docs.doc_file" target="_blank" download >View Document</a></td>
                  <td>--</td>
                </tr>
              </tbody>
            </table>
          
        </b-card-body>
      </b-collapse>
    </b-card>

    
  </div>
       

<!-- The modal -->
<b-modal 
id="my-modal"
centered
header-bg-variant="primary"
header-text-variant="light"
title="Add Document"
ok-title="Upload Document"
@ok="UploadDocuments"

>

<b-form-radio-group
      v-model="selected"
      :options="options"
      class="mb-3 mx-5"
      value-field="item"
      text-field="name"
      disabled-field="notEnabled"
    ></b-form-radio-group>
    <div v-if="selected==1">
      <b-form-group
        id="input-group-1"
        label="Folder Name"
        label-for="input-1"
        description="We'll never share your email with anyone else."
      >
        <b-form-input
          id="input-1"
          v-model="foldername"
          type="text"
          placeholder="Enter Folder Name"
          required
        ></b-form-input>
      </b-form-group>
    </div>
    <div v-else-if="selected==2">
      <b-form-group
        id="input-group-1"
        label="Select Folder "
        label-for="input-1"
        description="We'll never share your email with anyone else."
      >
      <b-form-select v-model="selected_option" :options="Select_options"></b-form-select>
    </b-form-group>
  </div>
  <b-form-group
        id="input-group-1"
        label="File Name"
        label-for="input-1"
        description="We'll never share your email with anyone else."
      >
     
        <b-form-input
          id="input-1"
          v-model="fileName"
          type="text"
          placeholder="Enter File Name"
          required
        ></b-form-input>
      </b-form-group>
      <input type="file" ref="Docfile" />
 <!--  <b-form-file
                      
  ref="Docfile"
                    
                    ></b-form-file> -->
                
  <!-- <b-form-file
      v-model="file1"
      :state="Boolean(file1)"
      multiple
      placeholder="Choose a file or drop it here..."
      drop-placeholder="Drop file here..."
      :file-name-formatter="formatNames"
      ref="file"
    ></b-form-file> -->
    
</b-modal>

    <b-row>

                         <!--  <b-col sm="12" md="12">
                            <div class="card  mb-2">
                            <div class="d-flex bd-highlight  ">
  <div class="p-2 flex-fill bd-highlight">Document name 1</div>
  <div class="p-2 flex-fill bd-highlight">
    <span @click="$refs.file1.click()" class="cursor-pointer"><i class="mdi mdi-upload text-danger" ></i> Upload
            <input type="file" ref="file1" style="display: none"  />
		</span>
  </div>
</div>
</div> 
<div class="card  mb-2">
                            <div class="d-flex bd-highlight ">
  <div class="p-2 flex-fill bd-highlight">Document name 2</div>
  <div class="p-2 flex-fill bd-highlight">
    <span @click="$refs.file2.click()" class="cursor-pointer"><i class="mdi mdi-upload text-danger" ></i> Upload
            <input type="file" ref="file2" style="display: none"  />
		</span>
  </div>
</div>
</div>  


                        
                        </b-col>-->
                       
                    </b-row>
                </div> 
  </div>
</template>

<script>

import axios from "axios";
//import { mapState } from "vuex";
	

	export default {
		name: "Document",
		data: () => ({
			title: "Document",
			today_date: new Date().toISOString().substr(0, 10),
      document : [],
      documents : [],
      files_day_light : [],
      selected : '',
      selected_option : '',
      foldername : '',
      file_day_light:'',
      fileName:'',
      backend_url:'',
      options: [
          { item: '1', name: 'New Folder' },
          { item: '2', name: 'Existing Folder' },
        ],
      Select_options: []
		}),
    methods:
    {
      load_document()
      {
        axios
					.get("/api/document").then((resp) => {
						this.document = resp.data.all_document;
						this.documents = resp.data.documents;

            for(var i=0;i<resp.data.all_document.length;i++)
            {
              this.Select_options.push(
                {
                  value:resp.data.all_document[i].id,
                  text:resp.data.all_document[i].name
                }
              )
            }
          })
      },
      addFolder()
      {
        axios
					.post("/api/add_folder_document").then((resp) => {
            resp
						this.load_document();
          })
      },
      formatNames(files) {
        return files.length === 1 ? files[0].name : `${files.length} files selected`
      },
     /*  UploadDocuments()
      {
        let formData = new FormData()
        if (this.files_day_light.length != 0) {
            for (var i = 0; i < this.files_day_light.length; i++) {
              formData.append("day_arr[]", this.files_day_light[i]);
            }
          }
        formData.append('select_type', this.selected);
        formData.append('Folder_name', this.foldername);
        formData.append('Choose_folder', this.selected_option);

axios.post('/api/document_file_upload', formData, {
    headers: {
        'Content-Type': 'multipart/form-data'
    },
  }
).then(function(){
})
.catch(function(){
}); */
     // },
      
			UploadDocuments() {
        let file = this.$refs.Docfile.files[0];
				var formData = new FormData();
        formData.append('select_type', this.selected);
        formData.append('Folder_name', this.foldername);
        formData.append('Choose_folder', this.selected_option);
        formData.append('name', this.fileName);
        /* if (this.files_day_light.length != 0) {
            for (var i = 0; i < this.files_day_light.length; i++) {
              formData.append("day_arr[]", this.files_day_light[i]);
            }
          } */
			
        formData.append("files", file);
          axios.post("/api/document_file_upload",formData).then((resp) => {
            resp
            this.load_document();
          });
        /*   axios.post('/api/document_file_upload', formData
).then(function(){
  this.load_document();
})
.catch(function(){
  this.load_document();
}); */
      },
      file_day_light(files) {
				return files.length === 1
				? files[0].name
				: `${files.length} files are selected`;
			},
    },
    mounted()
    {
      this.load_document();
      this.backend_url=process.env.VUE_APP_APIURL;
    }
  }
</script>

<style>

</style>