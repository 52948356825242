<template>
  <div>
    <b-row class="mt-5">
        <b-col sm="3">
            <div class="card shadow-2 rounded-50">
                <div class="d-flex justify-content-between p-3">
                    <div> <img src="https://www.freepnglogos.com/uploads/whatsapp-png-image-9.png" class="image-size"></div>
                    <div> 
                        <button :class="{'custom-new-button bt-sm btn mt-2':true,[obj.whatsapp? 'btn-success' :'btn-danger']:true}" @click="connect_connection('whatsapp',obj.whatsapp ? false:true)">{{obj.whatsapp? 'Connected' : 'Connect' }}</button>
                    </div>
                </div>
                <p class="p-2 fs-10">

Whatsapp is a great app for smartphones and that is a popular Android and iOS messaging and voice chat application           </p>
            </div>
        </b-col>
        <b-col sm="3">
            <div class="card shadow-2 rounded-50">
                <div class="d-flex justify-content-between p-3">
                    <div> <img src="https://1000logos.net/wp-content/uploads/2017/08/Chrome-Logo.png" class="image-size"></div>
                    <div> 
                        <button :class="{'custom-new-button bt-sm btn mt-2':true,[obj.chrome? 'btn-success' :'btn-danger']:true}" @click="connect_connection('chrome',obj.chrome ? false:true)">{{obj.chrome? 'Connected' : 'Connect' }}</button>
                    </div>
                </div>
                <p class="p-2 fs-10">

Whatsapp is a great app for smartphones and that is a popular Android and iOS messaging and voice chat application           </p>
            </div>
        </b-col>
        <b-col sm="3">
            <div class="card shadow-2 rounded-50">
                <div class="d-flex justify-content-between p-3">
                    <div> <img src="https://img.freepik.com/premium-vector/blue-social-media-logo_197792-1759.jpg" class="image-size"></div>
                    <div> 
                        <button :class="{'custom-new-button bt-sm btn mt-2':true,[obj.facebook? 'btn-success' :'btn-danger']:true}" @click="connect_connection('facebook',obj.facebook ? false:true)">{{obj.facebook? 'Connected' : 'Connect' }}</button>
                    </div>
                </div>
                <p class="p-2 fs-10">

Whatsapp is a great app for smartphones and that is a popular Android and iOS messaging and voice chat application           </p>
            </div>
        </b-col>
        <b-col sm="3">
            <div class="card shadow-2 rounded-50">
                <div class="d-flex justify-content-between p-3 ">
                    <div> <img src="https://upload.wikimedia.org/wikipedia/commons/0/09/Tally_-_Logo.png" class="image-size"></div>
                    <div> 
                        <button :class="{'custom-new-button bt-sm btn mt-2':true,[obj.tally? 'btn-success' :'btn-danger']:true}" @click="connect_connection('tally',obj.tally ? false:true)">{{obj.tally? 'Connected' : 'Connect' }}</button>
                    </div>
                </div>
                <p class="p-2 fs-10">

Whatsapp is a great app for smartphones and that is a popular Android and iOS messaging and voice chat application           </p>
            </div>
        </b-col>
  </b-row>
  </div>
</template>

<script>
import axios from 'axios';

export default {

    data: () => ({
        obj:[]
    }),
    methods:
    {
        connect_connection(connected,status)
        {
            axios.post("/api/api_enable",{app:connected, value:status}).then((resp) => {
                resp
                this.app_connection_view();
            });
        },
        app_connection_view()
        {
            axios.get("/api/connection_view").then((resp) => {
               this.obj = JSON.parse(resp.data.app_settings);

            });
        }

    },
    mounted()
    {
        this.app_connection_view();
    }
}
</script>

<style>
.fs-10
{
    font-size:10px;
}
.custom-new-button
{
    width: 70px;
    height: 25px;
    font-size: 9px;
}
.rounded-50
{
    border-radius:15px;
}
.image-size
{
    width: 50px;
    max-height: 50px;
    -o-object-fit: cover;
    min-height: 50px;
    object-fit: cover;
}
</style>