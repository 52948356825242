<template>
	<div>
		
		<transition >
		<b-tabs class="myprofile-tab" v-model="selectedTab">
			<!-- Profile section-->
			<b-tab title="Profile" active v-if="profile_access[1].vb_view==1">
				<Profile />
			</b-tab>
			<!-- Profile section-->
			
            <!-- Password section-->
			<b-tab title="Password"  v-if="profile_access[2].vb_view==1">
				<ResetPassword />
			</b-tab> 
			<!-- Password section-->

			

			<b-tab title="KYC" :active="selectedTabs=='kyc'"  v-if="profile_access[3].vb_view==1">
				<kyc />
				<!-- <div class="d-flex justify-content-between mt-3">
					<div>
						
					</div>
					
				</div>
                <b-row>
                    <b-col sm="12 ">
						
						<div class="mt-3" v-if="verify_pan.pan_no == null">
						<b-row>
							<b-col cols="3">PAN Number</b-col>
							<b-col cols="5">
								
								<b-form-input class="form-control venue-custom-input"
								placeholder="Enter PAN number."
								v-model="$v.kyc_tab.pan_number.$model"
								:state="validateKycTab('pan_number')">
							
								</b-form-input>
								<b-form-invalid-feedback
								id="pehrs-1-live-feedback"
								v-if="$v.kyc_tab.pan_number.$error"
								>
									<div v-if="!$v.kyc_tab.pan_number.required">
										Please enter pan number 
									</div>
									<div v-if="!$v.kyc_tab.pan_number.panNumberCheck">
										Please enter valid pan number 
									</div>
								</b-form-invalid-feedback>
							</b-col>
							<b-col cols="3">
								<b-button class="btn-venuecolor" @click="callpan()">Get Details</b-button>
							</b-col>
							
						</b-row>
						<b-form-checkbox id="checkbox-1" v-model="status" name="checkbox-1"   class="mt-3">
							If you don't have PAN Card
						</b-form-checkbox> 
						
						<div class="" v-if="status"> 
							<b-row>
								<b-col cols="4" class="mt-3">
									<b-col cols="12">
										<b-form-input class="form-control venue-custom-input"
										placeholder="Enter GST number."
										v-model="$v.kyc_tab.gst_number.$model"
										:state="validateKycTab('gst_number')"
										:disabled="kyc_tab.disable_get_pan"
										>
										</b-form-input>
										<b-form-invalid-feedback
										id="pehrs-1-live-feedback"
										v-if="$v.kyc_tab.gst_number.$error"
										>
											<div v-if="!$v.kyc_tab.gst_number.gstNumberCheck">
												Please enter valid gst number 
											</div>
										</b-form-invalid-feedback>
									</b-col>
									<b-col cols="12">
										<b-form-input class="form-control venue-custom-input"
										placeholder="Enter pincode"
										v-model="$v.kyc_tab.pincode.$model"
										:state="validateKycTab('pincode')"
										@keyup="pincodeCheckV()">
										</b-form-input>
										<b-form-invalid-feedback
										id="pehrs-1-live-feedback"
										v-if="!$v.kyc_tab.pincode.required"
										>
											Please enter pincode
										</b-form-invalid-feedback>
									</b-col>
								</b-col> 
								<b-col cols="4" class="mt-3">
									<b-col cols="12">
										<b-form-input class="form-control venue-custom-input"
										placeholder="Enter state"
										v-model="$v.kyc_tab.state.$model"
										:state="validateKycTab('state')"
										>
										</b-form-input>
										<b-form-invalid-feedback
										id="pehrs-1-live-feedback"
										v-if="!$v.kyc_tab.state.required"
										>
											Please enter state
										</b-form-invalid-feedback>
										
									</b-col>
									<b-col cols="12">
										<b-form-input class="form-control venue-custom-input"
										placeholder="Enter Legal name."
										v-model="$v.kyc_tab.company_name.$model"
										:state="validateKycTab('company_name')"
										>
										</b-form-input>
										<b-form-invalid-feedback
										id="pehrs-1-live-feedback"
										v-if="!$v.kyc_tab.company_name.required"
										>
											Please enter Legal name
										</b-form-invalid-feedback>
									</b-col>
								</b-col> 
								<b-col cols="4" class="mt-3">
									
									<b-col cols="12">
										<b-form-input class="form-control venue-custom-input"
										placeholder="Enter city name"
										v-model="$v.kyc_tab.city.$model"
										:state="validateKycTab('city')"
										>
										</b-form-input>
										<b-form-invalid-feedback
										id="pehrs-1-live-feedback"
										v-if="!$v.kyc_tab.city.required"
										>
											Please enter city name
										</b-form-invalid-feedback>
									</b-col>
									<b-col cols="12">
										<b-form-input class="form-control venue-custom-input"
										placeholder="Enter address"
										v-model="$v.kyc_tab.address.$model"
										:state="validateKycTab('address')"
										>
										</b-form-input>
										<b-form-invalid-feedback
										id="pehrs-1-live-feedback"
										v-if="!$v.kyc_tab.address.required"
										>
											Please enter address
										</b-form-invalid-feedback>
									</b-col>
								</b-col> 
							</b-row>
							<b-row class="mt-3">
								<b-col cols="9 " >
									<button class="btn btn-venuecolor btn-sm btn-sm mx-auto " @click="updatepan()" >Submit </button>
								</b-col>
							</b-row> 
						</div>
					</div>
               
                    <h6 class="custom-text "><span>KYC Verification</span></h6>
						<div class="">
						<div class="row">
							<div class="col-md-7">
							<table class="table ">
								<thead>
									<tr>
										<th class="col-md-1"></th>
										<th class="col-md-2">Applicable</th>
										<th class="col-md-4">Number</th>
										<th class="">Status</th>
									</tr>
								</thead>
								<tbody>
									<tr class="">
										<td scope="row">PAN</td>
										<td>
											<span  class="cursor-pointer" v-bind:class="{ 'text-success' : verify_pan.pan_no != null }">
												<span v-if="verify_pan.pan_no != null">Yes</span> 
												<span v-if="verify_pan.pan_no == null" @click="kyctab(1)">Yes</span>  
											</span> |
											<span v-bind:class="{ 'text-danger' : verify_pan.pan_no === null }" >
												No
											</span>
											
											</td>
										<td>{{ verify_pan.pan_no ? verify_pan.pan_no: 'Not Added'}}</td>
										
										<td>
											<span v-if="verify_pan.pan_no===null">
												<b-badge variant="danger">Pending</b-badge>
											</span>
											<span v-else>
												<b-badge variant="success">Verified</b-badge>
											</span>
										</td>
									</tr>
									<tr class="">
										<td scope="row">GST</td>
										<td>
											<span   class="cursor-pointer" v-bind:class="{ 'text-success' : verify_pan.gst_no != null }">
												<span v-if="verify_pan.gst_no == null" @click="kyctab(1)">Yes</span> 
												<span v-if="verify_pan.gst_no != null" >Yes</span>  
											</span> |
											<span v-bind:class="{ 'text-danger' : verify_pan.gst_no === null }" >
												No
											</span>
											
										</td>
										<td>{{ verify_pan.gst_no ? verify_pan.gst_no: 'Not Added'}}</td>
										
										<td>
											<span v-if="verify_pan.gst_no==null">
												<b-badge variant="danger">Pending</b-badge>
											</span>
											<span v-else>
												<b-badge variant="success">Verified</b-badge>
											</span>
										</td>
									</tr> 
									<tr class="">
										<td scope="row">TAN</td>
										<td>
												<span   class="cursor-pointer" v-bind:class="{ 'text-success' : verify_pan.tan_no != null }" >
											
												<span v-if="verify_pan.tan_no == null" @click="kyctantab(1)">Yes</span> 
												<span v-if="verify_pan.tan_no != null" >Yes</span>  
											</span> |
											<span v-bind:class="{ 'text-danger' : verify_pan.tan_no === null }" >
												No
											</span>
											
										</td>
										<td>{{ verify_pan.tan_no ? verify_pan.tan_no: 'Not Added'}}</td>
											
										<td>
											<span v-if="verify_pan.tan_no==null">
												<b-badge variant="danger">Pending</b-badge>
											</span>
											<span v-else>
												<b-badge variant="success">Verified</b-badge>
											</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="col-md-5 ">
							<div class="card custom-card">
							<div class="row ">
								<div class="col-md-4"> 
									Legal name  : 
								</div>
								<div class="col-md-8"> 
									{{json_parse(verify_pan.gst_pan_details).legal_name}}
								</div>
								<div class="col-md-4"> 
									Pincode  : 
								</div>
								<div class="col-md-8"> 
									{{json_parse(verify_pan.gst_pan_details).pincode}}
								</div>
								<div class="col-md-4"> 
									City  : 
								</div>
								<div class="col-md-8"> 
									{{json_parse(verify_pan.gst_pan_details).city}}
								</div>
								<div class="col-md-4"> 
									State  : 
								</div>
								<div class="col-md-8"> 
									{{json_parse(verify_pan.gst_pan_details).state}}
								</div>
								<div class="col-md-4"> 
									Address  : 
								</div>
								<div class="col-md-8"> 
									{{json_parse(verify_pan.gst_pan_details).address}}
								</div>
								
							</div>
							</div>
							
						</div>
					</div>
					</div>
					</b-col>
                    <b-col sm="12">
                        <h6 class="custom-text"><span>Bank Details</span></h6>
						<span v-if="this.verify_pan.account_holder_name!=json_parse(verify_pan.gst_pan_details).legal_name">
							<b-alert show variant="danger" size="sm">Your Account name and Legal name doesnt match. please contact customer care</b-alert>
						</span>
						<div class="row">
							<div class="col-md-7">
								<b-row >
							<b-col cols="6" >
								<b-row class="mt-3">
									<b-col cols="4" class="mt-2">Account No</b-col>
									<b-col cols="8">
										
										<b-form-group>
											<b-form-input class="form-control venue-custom-input"
											id="c_name"
											type="text"
											placeholder="Enter account no"
											v-model="bank_form.account_no"
											:state="validateStateBank('account_no')"
											></b-form-input>
											<b-form-invalid-feedback id="first_name-1-live-feedback">
												<span v-if="!this.$v.bank_form.account_no.required">
													Account no is required</span
												>
											</b-form-invalid-feedback>
											<b-form-invalid-feedback id="first_name-1-live-feedback">
												<span v-if="!this.$v.bank_form.account_no.accountNumberCheck">
													Please enter valid account number</span
												>
											</b-form-invalid-feedback>
										</b-form-group>
									</b-col>
									
								</b-row>
								<b-row class="mt-3">
									<b-col cols="4" class="mt-2">IFSC Code</b-col>
									<b-col cols="8">
										<b-form-group>
											<b-form-input class="form-control venue-custom-input"
											id="c_name"
											type="text"
											placeholder="Enter IFSC code"
											v-model="bank_form.ifsc_code"
											:state="validateStateBank('ifsc_code')"
											@blur="IfsccodeCheck()"
											></b-form-input>
											<b-form-invalid-feedback id="first_name-1-live-feedback">
												<span v-if="!this.$v.bank_form.ifsc_code.required">
													IFSC code is required</span
												>
											</b-form-invalid-feedback>
											<b-form-invalid-feedback id="first_name-1-live-feedback">
												<span v-if="!this.$v.bank_form.ifsc_code.IFSCNumberCheck">
													Please enter valid IFSC code</span
												>
											</b-form-invalid-feedback>
										</b-form-group>
									</b-col>
									
								</b-row>
								
							</b-col>
                            <b-col cols="6" >
                                
							</b-col>
						</b-row>
                        <b-row class="mt-3">
							<b-col cols="9 " offset-md="3">
								<span v-if="this.verify_pan.account_no==null">
									<button  class="btn btn-venuecolor btn-sm btn-sm mx-auto w-50"  @click="get_bank_details()" :disabled="this.bank_form.get_detail_btn_disable">Verify </button>
								
								</span>
								<span v-else>
								

								<button v-if="bank_form.edit_bank_details==false" class="btn btn-venuecolor btn-sm btn-sm mx-auto w-50"  @click="get_bank_details()" :disabled="this.bank_form.get_detail_btn_disable">Verify </button>
								</span>
							</b-col>
						</b-row> 
							</div>
							<div class="col-md-5">
						<div class="card custom-card">
							<div class="row ">
								
								
								<div class="col-md-4"> 
									Account Holder  : 
								</div>
								<div class="col-md-7"> 
									{{ this.verify_pan.account_holder_name }}
								</div>
								<div class="col-md-1">
									<span v-if="this.verify_pan.account_no!=null">
									<b-button  @click="edit_bank_details_fun()" variant="primary"  size="sm" v-if="bank_form.edit_bank_details==true">
								<i class="mdi mdi-pencil"></i></b-button>
									</span>
								</div>
								<div class="col-md-4"> 
									Account No  : 
								</div>
								<div class="col-md-8"> 
									{{ this.verify_pan.account_no }}
								</div>
								<div class="col-md-4"> 
									Bank name  : 
								</div>
								<div class="col-md-8"> 
									{{ this.verify_pan.bank_name }}
								</div>
								<div class="col-md-4"> 
									Branch name  : 
								</div>
								<div class="col-md-8"> 
									{{ this.verify_pan.branch_name }}
								</div>
								<div class="col-md-4"> 
									Status  : 
								</div>
								<div class="col-md-8"> 
									<span class="text-danger" v-if="this.verify_pan.account_holder_name!=json_parse(verify_pan.gst_pan_details).legal_name">
									Account Not Verified
									</span>
									<span class="text-danger" v-else-if="this.verify_pan.account_no==null">
									Verification Pending
									</span>
									<span class="text-success" v-else>
									Account Verified
									</span>
								</div>
								
							</div>
							</div>
							</div>
						</div>
			
					</b-col>
                    
				</b-row>
				
                <b-modal id="modal-1" centered title="KYC Update" no-close-on-esc  no-close-on-backdrop header-bg-variant="primary" header-text-variant="light" hide-footer>
                    <b-row>
                        <b-col cols="3">PAN Number</b-col>
                        <b-col cols="5">
						<b-form-input class="form-control venue-custom-input"
								placeholder="Enter PAN number."
								v-model="$v.kyc_tab.pan_number.$model"
								:state="validateKycTab('pan_number')">
							
								</b-form-input>
								<b-form-invalid-feedback
								id="pehrs-1-live-feedback"
								v-if="$v.kyc_tab.pan_number.$error"
								>
									<div v-if="!$v.kyc_tab.pan_number.required">
										Please enter pan number 
									</div>
									<div v-if="!$v.kyc_tab.pan_number.panNumberCheck">
										Please enter valid pan number 
									</div>
								</b-form-invalid-feedback>
					</b-col>
                        <b-col cols="3">
                            <b-button variant="primary" @click="callpan()">Get Details</b-button>
						</b-col>
						
					</b-row>
                   
                    
                   
                    <div class="">
                        <b-row>
							<b-col cols="6" class="mt-3">
                                <b-col cols="12">
                                    <input type="text" class="form-control venue-custom-input" 
									name="" id="" aria-describedby="helpId" placeholder="Enter GST Number" v-model="kyc_tab.gst_number">
								</b-col>
                                <b-col cols="12">
                                    <input type="text" class="form-control venue-custom-input" 
									name="" id="" aria-describedby="helpId" placeholder="Enter Pincode"  v-model="kyc_tab.pincode">
								</b-col>
                                <b-col cols="12">
                                    <input type="text" class="form-control venue-custom-input" 
									name="" id="" aria-describedby="helpId" placeholder="Enter State"  v-model="kyc_tab.state">
									
								</b-col>
							</b-col> 
							<b-col cols="6" class="mt-3">
                                <b-col cols="12">
                                    <input type="text" class="form-control venue-custom-input"  v-model="kyc_tab.company_name" name="" id="" aria-describedby="helpId" placeholder="Enter Legal name">
								</b-col>
                                <b-col cols="12">
                                    <input type="text" class="form-control venue-custom-input"  v-model="kyc_tab.city" name="" id="" aria-describedby="helpId" placeholder="Enter City">
								</b-col>
                                <b-col cols="12">
                                    <input type="text" class="form-control venue-custom-input"  v-model="kyc_tab.address" name="" id="" aria-describedby="helpId" placeholder="Address">
								</b-col>
								
							</b-col> 
							
							
						</b-row>
                        <b-row class="mt-3">
							<b-col cols="9 " offset-md="3">
								<button class="btn btn-venuecolor btn-sm btn-sm mx-auto w-50"  @click="get_bank_details()" >get details </button>
							</b-col>
						</b-row> 
					</div>
				</b-modal>
				
				<b-modal id="modal-2" centered title="TAN KYC Update" no-close-on-esc  no-close-on-backdrop header-bg-variant="primary" header-text-variant="light" hide-footer>
					
                    <div class="">
                        <b-row>
							<b-col cols="12" class="mt-3">
								<b-form-group
								label="TAN No"
								label-for="tan-input"
								>
									<b-form-input class="form-control venue-custom-input"
									id="tan-input"
									v-model="tan_form.tan_no"
									:state="validateState('tan_no')"
									></b-form-input>
									<b-form-invalid-feedback id="first_name-1-live-feedback">
										<span v-if="!this.$v.tan_form.tan_no.required">
											TAN no is required</span
										>
									</b-form-invalid-feedback>
								</b-form-group>
								<b-form-textarea
								size="lg"
								placeholder="All the message and the brief details also with government portal link"
								disabled=""
								></b-form-textarea>
								<b-form-checkbox
								v-model="tan_form.acknowledge_status"
								:state="validateState('acknowledge_status')"
								>
									Acknowledge
								</b-form-checkbox>
								<b-form-invalid-feedback id="first_name-1-live-feedback">
									<span v-if="!this.$v.tan_form.acknowledge_status.required">
										Please accept acknowledge.</span
									>
								</b-form-invalid-feedback>
							</b-col> 
						</b-row>
                        <b-row class="mt-3">
							<b-col cols="9 " offset-md="3">
								<button class="btn btn-venuecolor btn-sm btn-sm mx-auto w-50" @click="handleTanSubmit()">Save </button>
							</b-col>
						</b-row> 
					</div>
					
				</b-modal> -->
				
			</b-tab>
			<b-tab title="Document" v-if="profile_access[4].vb_view==1">
				<Contructs/>
			</b-tab>
			<b-tab title="Teams"  :active="selectedTabs=='teams'" v-if="profile_access[5].vb_view==1">
			<Teams/>
			</b-tab>
			<b-tab title="API Integration" v-if="profile_access[6].vb_view==1">
				<Api/>
			</b-tab>
			<b-tab title="Notification" :active="selectedTabs=='notification'" v-if="profile_access[7].vb_view==1">
				
				<notification/>
			</b-tab> 
			
			
		</b-tabs>
		</transition>
	</div>
</template>

<script>
import axios from "axios";
	import ResetPassword from "./Reset_password/index"
	import Profile from "./Profile/index"
	import kyc from "./KYC/index"
	import notification from "./Notification/index"
	import Api from "./Api/index.vue"
	import Contructs from "./Contructs/index.vue"
	import Teams from "./Teams/index.vue"
	export default {
		data() {
			return {
				profile_access:''
			}
		},
		components:{
			ResetPassword,
			Profile,
			kyc,
			notification,
			Api,
			Contructs,
			Teams,
		},
		methods:
		{
			access_permission()
			{
				axios
					.post("/admin/access_permission_view").then((resp) => {
						this.profile_access = resp.data;
					});
			},

		},
		mounted()
		{
			this.access_permission();
		}
	}
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

</style>