<template>
	<div>
		<div class="d-flex justify-content-between mt-3">
			<div>
				<!-- <h4> Admin ID - {{ user.vendor_auto_id }}
					
				</h4> -->
			</div>
			dsdsdsd
			<div>
				<button class="btn btn-venuecolor btn-sm" v-show="edit" @click="save_profile()">Save</button>
				<button class="btn btn-venuecolor btn-sm"  @click="enable_edit()"  v-if="edit==false"> Edit <i class="mdi mdi-pencil"></i></button>
				<button class="btn btn-default btn-sm" @click="enable_close()" v-if="edit==true" ><i class="mdi mdi-close"  ></i> Cancel</button>
				
				
			</div>
		</div> 
		<b-row class="mt-4" >
		
			<b-col md="2" >
				<div class="profile-card">
					<!-- <v-lazy-image v-bind="mainProps" rounded="circle"  :src="user.user_image ? backend_url+'/'+user.user_image : '/img/1.5954212d.jpg'"/> -->
				<b-img v-bind="mainProps" rounded="circle" alt="Circle image" :src="user.user_image ? backend_url+'/'+user.user_image : '/img/1.5954212d.jpg'" > </b-img>
					<div class="profile-header-content">
						<p class="mt-3 venue-color-text">{{ user.f_name }} {{ user.l_name }}</p>
						
					</div>	
				</div>
				<h6 class="custom-text mt-5"><span>Currency</span></h6>
				<select class="form-control venue-custom-input" v-model="user.currencys" :disabled="edit==false">
					<option value="" selected >Select Currency</option>
					<option  v-for="(countr,c) in country" :key="c" :value="countr.currency">{{countr.name}}</option>
				</select>
<br>
<br>
<br>
				<i class="mdi mdi-upload mt-5 " @click="$refs.logofile.click()"></i>Upload Logo
			<input type="file" ref="logofile" style="display: none" @change="logofileUpload()"/>

			</b-col>
			<i class="mdi mdi-pencil position-absolute custom-venue-editbutton" @click="$refs.file.click()"></i>
			<input type="file" ref="file" style="display: none" @change="imagefileUpload()"/>
			
			<b-col md="5">
				<h6 class="custom-text"><span>Personal Details</span></h6>
				<b-row>
					<b-col cols="4" md="4" class="text-left mt-2">First name</b-col>
					<b-col cols="12" md="8" sm="12">
						
						<b-form-group id="f_name"  >
							<b-form-input 
							class="form-control venue-custom-input" :state="validateStateusers('f_name')" :disabled="edit==false" 
							v-model="$v.user.f_name.$model" name="" id="" aria-describedby="helpId" placeholder=" first name">
							</b-form-input>
							<b-form-invalid-feedback v-if="!this.$v.user.f_name.required">
								Please enter First name
							</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
					
				</b-row>
				<!-- <b-row>
					<b-col cols="4" md="4" class="text-left mt-2">Middle name</b-col>
					
					<b-col cols="12" md="8"  sm="12">
					<b-form-group id="m_name"  >
					<input type="text"
					class="form-control venue-custom-input" :disabled="edit==false" v-model="user.l_name" name="" id="" aria-describedby="helpId" placeholder=" Middle name">
					
					</b-form-group>
					</b-col>
				</b-row> -->
			<!-- 	<b-row>
					<b-col cols="4" md="4" class="text-left mt-2">Last name</b-col>
					
					<b-col cols="12" md="8"  sm="12">
						<b-form-group id="l_name"  >
							<b-form-input  type="text"
							class="form-control venue-custom-input" :disabled="edit==false"  name="" 
							id="" aria-describedby="helpId" placeholder=" Last name"
							v-model="$v.user.l_name.$model" :state="validateStateusers('l_name')" >
							</b-form-input>
							<b-form-invalid-feedback v-if="!this.$v.user.l_name.required">
								Please enter Last name
							</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
				</b-row> -->
				<b-row>
					<b-col cols="4" md="4" class="text-left mt-2">Phone number</b-col>
					<b-col cols="12" md="8">
						<b-form-group id="phone"  >
							<b-form-input  type="text"
							class="form-control venue-custom-input" :disabled="edit==false"
							v-model="$v.user.phone.$model" :state="validateStateusers('phone')" 
							name="" id="" aria-describedby="helpId" placeholder="Enter phone number">
							</b-form-input>
							<b-form-invalid-feedback>
								<span v-if="!this.$v.user.phone.required">
									Please enter Phone number
								</span>
								<span v-if="this.$v.user.phone.required && !this.$v.user.phone.minLength">
									Mobile number must be 10 characters long.
								</span>
								<span v-if="this.$v.user.phone.required && !this.$v.user.phone.maxLength ">
									Mobile number must be 12 characters long.
								</span>
							</b-form-invalid-feedback>
						</b-form-group> 
					</b-col>
					
				</b-row>
				
				
				<h6 class="custom-text mt-4"><span>Address Details</span></h6>
				<b-row>
					<b-col cols="4" md="4" class="text-left mt-2">Address</b-col>
					<b-col cols="12" md="8" class="mt-2">
						
						<b-form-group id="address"  >
							<b-form-textarea 
							class="form-control venue-custom-input" :disabled="edit==false"  name="" 
							id="" aria-describedby="helpId" placeholder="Enter Address"
							v-model="$v.user.address.$model" :state="validateStateusers('address')" >
							</b-form-textarea>
							<b-form-invalid-feedback v-if="!this.$v.user.address.required">
								Please enter address
							</b-form-invalid-feedback>
						</b-form-group>
						
					</b-col>
					
				</b-row>
				<b-row>
					<b-col cols="4" md="4" class="text-left mt-2"> Pincode</b-col>
					
					<b-col cols="12" md="8">
						<b-form-group id="pincode"  >
							<b-form-input 
							class="form-control venue-custom-input" :disabled="edit==false"  name="" 
							id="" aria-describedby="helpId" placeholder="Enter pincode"
							v-model="$v.user.pincode.$model" :state="validateStateusers('pincode')" @keyup="pincodeCheck()">
							</b-form-input>
							<b-form-invalid-feedback v-if="!this.$v.user.pincode.required">
								Please enter pincode
							</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
				</b-row>
				
				<b-row>
					
					<b-col cols="4" md="4" class="text-left mt-2">City</b-col>
					<b-col cols="12" md="8">
						
						<b-form-group id="city"  >
							<b-form-input 
							class="form-control venue-custom-input" :disabled="edit==false"  name="" 
							id="" aria-describedby="helpId" placeholder="Enter city"
							v-model="$v.user.city.$model" :state="validateStateusers('city')" >
							</b-form-input>
							<b-form-invalid-feedback v-if="!this.$v.user.city.required">
								Please enter city
							</b-form-invalid-feedback>
						</b-form-group>
						
					</b-col>
					
				</b-row> 
				<b-row>
					<b-col cols="4" md="4" class="text-left mt-2"> State</b-col>
					
					<b-col cols="12" md="8">
						
						<b-form-group id="state"  >
							<b-form-input 
							class="form-control venue-custom-input" :disabled="edit==false"  name="" 
							id="" aria-describedby="helpId" placeholder="Enter state"
							v-model="$v.user.state.$model" :state="validateStateusers('state')" >
							</b-form-input>
							<b-form-invalid-feedback v-if="!this.$v.user.state.required">
								Please enter state
							</b-form-invalid-feedback>
						</b-form-group>
						
					</b-col>
				</b-row>
				
				
				
			</b-col>
			
			<b-col md="5">
				
				<h6 class="custom-text "><span>Contact Details</span></h6>
				<b-row>
					<b-col cols="4" md="4" class="text-left mt-2">Email address</b-col>
					<b-col cols="12" md="8">
						<b-form-group id="email"  >
							<b-form-input  type="text"
							class="form-control venue-custom-input" :disabled="edit==false" 
							v-model="$v.user.email.$model" :state="validateStateusers('email')" name="" id="" aria-describedby="helpId" 
							placeholder="Enter Email Address">
							</b-form-input>
							<b-form-invalid-feedback>
								<span v-if="!this.$v.user.email.required">
									Please enter Valid Email address
								</span>
								<span v-if="this.$v.user.email.required && !this.$v.user.email.email">
									Please enter Valid Email address
								</span>
								
							</b-form-invalid-feedback>
						</b-form-group>
						
					</b-col>
					
				</b-row>
				<b-row>
					<b-col cols="4" md="4" class="text-left mt-5"> </b-col>
					
					<b-col cols="12" md="8">
						
						
					</b-col>
				</b-row>
				<!--<b-row>
					<b-col cols="4" md="4" class="text-left mt-2">Mobile number</b-col>
					<b-col cols="12" md="8">
					<b-form-group id="mobile"  >
					<b-form-input  type="text"
					class="form-control venue-custom-input" :disabled="edit==false"
					v-model="user.mobile"  
					name="" id="" aria-describedby="helpId" placeholder="Enter Mobile number">
					</b-form-input>
					
					</b-form-group> 
					</b-col>
					
				</b-row>-->
				<h6 class="custom-text mt-4"><span>About Yourself</span></h6>
				<b-row>
					<b-col cols="4" md="4" class="text-left mt-2">About</b-col>
					<b-col cols="12" md="8" class="mt-2">
						
						<b-form-group id="about"  >
							<b-form-textarea 
							class="form-control custom-disable-teatarea" :disabled="edit==false" rows="9" name="" 
							id="" aria-describedby="helpId" placeholder="Enter About yourself"
							v-model="user.about"  >
							</b-form-textarea>
							
						</b-form-group>
						
					</b-col>
					
				</b-row>
			</b-col>
		</b-row>
		
	</div>
</template>

<script>
	import axios from "axios";
	//import VLazyImage from "v-lazy-image/v2";
	import { mapState } from "vuex";
	import { validationMixin } from "vuelidate";
	
	import {
		required ,email ,minLength ,maxLength
	} from "vuelidate/lib/validators";
	
	export default {
        mixins: [validationMixin],
		data() {
			return {
				mainProps: {  width: 75, height: 75, class: 'm1' },
				user: {
					f_name: "",
					l_name: "",
					email: "",
					phone: "",
					city: "",
					state: "",
					pincode: "",
					id: "",
					address: "",
					mobile: "",
					about: "",
					user_image: "",
					vendor_auto_id: "",
					currencys: "",
					
				},
				edit: false,
				backend_url: "",
				country: []
			}
		},
		validations: {
			user: {
				f_name: { required },
				email: { required,email },
				address: { required },
				city: { required },
				state: { required },
				phone: { required, minLength: minLength(6) , maxLength: maxLength(12) },
				pincode: { required, minLength: minLength(6) },
			}
		},
		components:
		{
			//VLazyImage
		},
		methods:
		{
			validateStateusers(field) {
				const { $dirty, $error } = this.$v.user[field];
				return $dirty ? !$error : null;
			},
			getUserDetails() {
				axios.get("/admin/get_profile").then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							this.user.f_name = resp.data.users.name;
							this.user.l_name = resp.data.users.last_name;
							this.user.email = resp.data.users.email;
							this.user.pincode = resp.data.users.pincode;
							this.user.city = resp.data.users.city;
							this.user.state = resp.data.users.state;
							this.user.phone = resp.data.users.mobile;
							this.user.id = resp.data.users.id;
							this.user.vendor_auto_id = resp.data.users.id;
							this.user.user_image = resp.data.users.user_image;
							this.user.address = resp.data.users.address;
							this.user.about = resp.data.users.about;
							this.user.currencys = resp.data.users.currencys;
							this.country = resp.data.country;
							
						}
					}
				});
			},
			save_profile()
			{
				this.$v.user.$touch();
				console.log(!this.$v.user.$anyError);
				if (!this.$v.user.$anyError) {
					this.save_profile_changes();
				}
			},
			save_profile_changes()
			{
				axios.post("/admin/user_info_updates",this.user ).then((resp) => {
					console.log(resp);
					this.getUserDetails();
					this.$root.$refs.app.showToast("success", resp.data.message);
					this.enable_close();
				});
			},
			imagefileUpload()
			{
				const formData= new FormData();
				let file = this.$refs.file.files[0];
				console.log("wait");
				formData.append('image', file);
				axios.post("/admin/user_image_upload",formData ).then((resp) => {
					console.log(resp);
					this.getUserDetails();
				});
				
			}
			,logofileUpload()
			{
				const formData= new FormData();
				let file = this.$refs.logofile.files[0];
				console.log("wait");
				formData.append('image', file);
				axios.post("/admin/user_logo_upload",formData ).then((resp) => {
					console.log(resp);
					this.getUserDetails();
				});
				
			},
			enable_edit()
			{
				this.edit=true;
			},
			enable_close()
			{
				this.edit=false;
			},
            pincodeCheck()
			{
				const str=this.user.pincode;
				axios
				.post("/admin/pincodeCheck", {
					pincode: str
				})
				.then((resp) => {
					if (resp.data.status) {
						this.user.city=resp.data.pincode_city.division;
						this.user.state=resp.data.pincode_city.state;
						
						
					}
				});
			},
		},
		mounted() {
			this.user.f_name = this.all_user_data.name;
			this.user.l_name = this.all_user_data.last_name;
			this.user.email = this.all_user_data.email;

			this.user.phone = this.all_user_data.mobile;
			this.user.pincode = this.all_user_data.pincode;
			this.user.city = this.all_user_data.city;
			this.user.state = this.all_user_data.state;
			this.user.id = this.all_user_data.id;
			this.user.vendor_auto_id = this.all_user_data.vendor_auto_id;
			this.user.user_image = this.all_user_data.user_image;
			this.user.address = this.all_user_data.address;
			this.user.about = this.all_user_data.about;
			this.user.currencys = this.all_user_data.currencys;

			this.country = this.all_user_data.country;
			this.getUserDetails();
			this.backend_url=process.env.VUE_APP_APIURL;
		},
		computed: {
    ...mapState(["all_user_data"]),
    
  },
	}
</script>

<style>
	
</style>