<template>
    <div>
		
		
		<b-row>
			<b-col sm="8 ">
				<div class="d-flex justify-content-between mt-3">
					<div>
                        <h4>User and Teams </h4>
					</div>
					<div>
                        <!-- <b-button class="btn-venuecolor" @click="Refresh_role()">developer test</b-button> --> &nbsp; &nbsp; <b-button class="btn-venuecolor" @click="call_add()">Add User</b-button> &nbsp; &nbsp; <b-button class="btn-venuecolor" @click="role_add()">Add Roles</b-button>
					</div>
					
				</div>
				<h6 class="custom-text "><span>Primary User</span></h6>
				<table class=" table ">
					<thead >
						<tr>
							<th scope="col">Name</th>
							<th scope="col">Email</th>
							<th scope="col">Role</th>
							<th scope="col">Phone no.</th>
							<th scope="col"></th>
						</tr>
					</thead>
					<tr class="" v-for="(prim , p) in Primary" :key="p">
						<td scope="row">{{ prim.name }}</td>
						<td scope="row">{{ prim.email }}</td>
						<td scope="row">{{ prim.role_type }}</td>
						<td scope="row">{{ prim.mobile }}</td>
						
						<td>
							<button class="btn btn-success btn-sm"  >  Primary </button>  &nbsp; &nbsp;
							<!-- 	<button class="btn btn-venuecolor btn-sm"  @click="editTeam(Primary.user_id)"> <i class="mdi mdi-pencil"></i> Edit </button>  &nbsp; &nbsp; -->
							<button class="btn btn-venuecolor btn-sm"  @click="role_access(Primary.role_id,'fullaccess',Primary.role_type,Primary.name)"> <i class="mdi mdi-lock-open"></i>Full Access </button>
						</td>
					</tr>
				</table>
				
				<h6 class="custom-text "><span>Other User</span></h6>
				<table class=" table ">
					<thead >
						<tr>
							<th scope="col">Name</th>
							<th scope="col">Email</th>
							<th scope="col">Role</th>
							<th scope="col">Phone no.</th>
							<th scope="col"></th>
						</tr>
					</thead>
					<tbody>
						<tr class="" v-for="(loadTeam,l) in loadTeams" :key="l">
							<td scope="row">{{ loadTeam.name }}</td>
							<td scope="row">{{ loadTeam.email }}</td>
							<td scope="row">{{ loadTeam.role_type }}</td>
							<td scope="row">{{ loadTeam.mobile }}</td>
							
							<td>
								<!-- <button class="btn btn-venuecolor btn-sm"  @click="addtoPrimary(loadTeam.user_id)"> <i class="mdi mdi-plus"></i>  Primary </button>  &nbsp; &nbsp; -->
								<button class="btn btn-venuecolor btn-sm"  @click="editTeam(loadTeam.user_id)"> <i class="mdi mdi-pencil"></i> Edit </button>  &nbsp; &nbsp;
								<button class="btn btn-venuecolor btn-sm"  @click="role_access(loadTeam.role_id,'customaccess',loadTeam.role_type,loadTeam.name)"> <i class="mdi mdi-lock"></i>Role Access </button>
								<!-- <button class="btn btn-venuecolor btn-sm"  @click="newrole_access(loadTeam.role_id)"> <i class="mdi mdi-lock"></i>New Role Access </button> -->
							</td>
						</tr>
						
					</tbody>
				</table>
				
			</b-col>
			
			<b-col sm="4 ">
				<div class="d-flex justify-content-between mt-4">
					<div>
                        <h4>{{name_selected}} - {{ roles_selected }} Module Access </h4>
					</div>
					
				</div>
				<h6 class="custom-text "><span>Modules </span></h6>
				<div>
					<b-overlay :show="show" rounded="sm" opacity="0.69"
          blur="1px">
					<div class="accordion" role="tablist" >
						<b-card no-body class="mb-1"  v-for="(mod,i) in All_modules" :key="i" >
							<b-card-header header-tag="header" class="p-1" role="tab">
								<b-button block  class=" btn-venuecolor">
									<table class="padding-width">
										<tr>
											<td class="table-width" v-b-toggle="'accordion-'+i">{{ mod.parent_name }} 
												<b-spinner label="Spinning" small v-if="loaders[i]"></b-spinner>
												<span class="mdi mdi-check text-success" v-if="loaders_after[i]"></span>
												<span class="d-none">{{ loaders1 }}</span>
												<span class="d-none">{{ loaders2 }}</span>
											</td>
											<td>Create <b-form-checkbox :disabled="selected_role_access=='fullaccess'" switch size="sm" :checked="mod.create!=0" @change="select_all_mod($event,i,mod.id,'vb_create','selectall',mod.parent_name)"></b-form-checkbox></td>
											<td>Edit <b-form-checkbox :disabled="selected_role_access=='fullaccess'" switch size="sm" :checked="mod.edit!=0" @change="select_all_mod($event,i,mod.id,'vb_edit','selectall',mod.parent_name)"></b-form-checkbox></td>
											<td>View <b-form-checkbox :disabled="selected_role_access=='fullaccess'" switch size="sm" :checked="mod.view!=0" @change="select_all_mod($event,i,mod.id,'vb_view','selectall',mod.parent_name)"></b-form-checkbox></td>
										</tr>
									</table>
									
									
								</b-button>
							</b-card-header>
							<b-collapse :id="'accordion-'+i" visible accordion="my-accordion" role="tabpanel">
								<span v-for="(child,j) in mod.child_name" :key="j" >
									
									<b-button block  variant="success" class="mb-2 mt-2">
										

										<table class="padding-width">
										<tr>
											<td class="table-widths" v-b-toggle="'accordions-'+j">{{ child.child_name }}
												
											</td>
											<td> <b-form-checkbox :disabled="selected_role_access=='fullaccess'" switch size="sm" :checked="child.vb_create==1" @change="select_all_mod($event,i,0,'vb_create','subselectall',child.child_name)"></b-form-checkbox></td>
											<td><b-form-checkbox :disabled="selected_role_access=='fullaccess'" switch size="sm"  :checked="child.vb_edit==1" @change="select_all_mod($event,i,0,'vb_edit','subselectall',child.child_name)"></b-form-checkbox></td>
											<td><b-form-checkbox :disabled="selected_role_access=='fullaccess'" switch size="sm" :checked="child.vb_view==1" @change="select_all_mod($event,i,0,'vb_view','subselectall',child.child_name)"></b-form-checkbox></td>
										</tr>
									</table>
									</b-button>
									<b-collapse :id="'accordions-'+j" visible  class="mt-2" accordion="my-accordion_">
										
										<span v-for="(sub_child,k) in child.sub_child" :key="k" >
											<b-button block  variant="danger" class="mb-2 mt-2">
												
											<table class="padding-width">
												<tr>
													<td class="table-widths" v-b-toggle="'accordions1-'+k">{{ sub_child.subname }}</td>
													<td> <b-form-checkbox :disabled="selected_role_access=='fullaccess'" switch size="sm"  :checked="sub_child.vb_create==1" @change="select_all_mod($event,i,0,'vb_create','subsubselectall',sub_child.subname)"></b-form-checkbox></td>
													<td><b-form-checkbox :disabled="selected_role_access=='fullaccess'" switch size="sm"  :checked="sub_child.vb_edit==1" @change="select_all_mod($event,i,0,'vb_edit','subsubselectall',sub_child.subname)"></b-form-checkbox></td>
													<td><b-form-checkbox :disabled="selected_role_access=='fullaccess'" switch size="sm"  :checked="sub_child.vb_view==1" @change="select_all_mod($event,i,0,'vb_view','subsubselectall',sub_child.subname)"></b-form-checkbox></td>
												</tr>
											</table>
											</b-button>
											<b-collapse :id="'accordions1-'+k" visible  class="mt-2" accordion="my-accordion_1">
											<span v-for="(sub_child_child,l) in sub_child.sub_sub_child" :key="l" >
												<b-button block  variant="warning" class="mb-2 mt-2">
													
												<table class="padding-width">
												<tr>
													<td class="table-widths">{{ sub_child_child.mname }}</td>
													<td> <b-form-checkbox :disabled="selected_role_access=='fullaccess'" switch size="sm" :checked="sub_child_child.vb_create==1"  @change="select_all_mod($event,i,0,'vb_create','select',sub_child_child.mname)" ></b-form-checkbox></td>
													<td><b-form-checkbox :disabled="selected_role_access=='fullaccess'" switch size="sm"  :checked="sub_child_child.vb_edit==1"  @change="select_all_mod($event,i,0,'vb_edit','select',sub_child_child.mname)"></b-form-checkbox></td>
													<td><b-form-checkbox :disabled="selected_role_access=='fullaccess'" switch size="sm" :checked="sub_child_child.vb_view==1"  @change="select_all_mod($event,i,0,'vb_view','select',sub_child_child.mname)"></b-form-checkbox></td>
												</tr>
											</table>
												</b-button>
											</span>
										</b-collapse>
											
											
										</span>
										
										
									</b-collapse>
								</span>
								
							</b-collapse>
							
						</b-card>
						
						
						
						
					</div>
					</b-overlay> 
				</div>
				<div class="accordion" role="tablist" >
					<div class=""  v-for="(module,m) in Modules" :key="m">
						
						
						<table  v-b-toggle="'accordion-'+m" class="table btn-venuecolor mb-1">
							<tr>
								<td class="w-50 text-white text-left" >{{ module.i18n }}</td>
								<td>Create <b-form-checkbox :disabled="selected_role_access=='fullaccess'" switch size="md" :checked="module.Create==1 ? true :false " @change="updateValues($event,module.children[0].vb_view,module.Mid,'vb_create','selectall',module.i18n)" ></b-form-checkbox></td>
								<td>Edit <b-form-checkbox :disabled="selected_role_access=='fullaccess'" switch size="md" :checked="module.Edit==1 ? true :false " @change="updateValues($event,module.children[0].vb_view,module.Mid,'vb_edit','selectall',module.i18n)"></b-form-checkbox></td>
								<td>View <b-form-checkbox :disabled="selected_role_access=='fullaccess'" switch size="md" :checked="module.VIew==1 ? true :false " @change="updateValues($event,module.children[0].vb_view,module.Mid,'vb_view','selectall',module.i18n)" ></b-form-checkbox></td>
							</tr>
						</table>
						<!-- 	<div class="d-flex justify-content-between">
							<div> </div>
							<div></div>
							<div><b-form-checkbox :disabled="selected_role_access=='fullaccess'" switch size="md" :checked="module.VIew==1 ? true :false " @change="updateValues($event,module.children[0].vb_view,module.Mid,'vb_view')"></b-form-checkbox></div>
							<div><b-form-checkbox :disabled="selected_role_access=='fullaccess'" switch size="md" :checked="module.VIew==1 ? true :false " @change="updateValues($event,module.children[0].vb_view,module.Mid,'vb_view')"></b-form-checkbox></div>
							</div>
						-->
						
						
                        <b-collapse :id="'accordion-'+m" visible accordion="my-accordion" role="tabpanel" v-if="module.children.length > 1">
							<table class="table">
                                <thead>
                                    <tr>
                                        <th>Sub Module </th>
										<th>Create </th>
										<th>Edit </th>
                                        <th>View </th>
                                        
                                        
									</tr>
								</thead>
                                <tbody>
                                    <tr v-for="(child ,c) in module.children" :key="c">
                                        <td>{{ child.subname }}</td>
										<td><b-form-checkbox :disabled="selected_role_access=='fullaccess'" switch size="md" :checked="child.vb_create==1" @change="updateValues($event,child.vb_create,child.id,'vb_create','select',child.subname)" ></b-form-checkbox></td>
										
										<td><b-form-checkbox :disabled="selected_role_access=='fullaccess'" switch size="md" :checked="child.vb_edit==1" @change="updateValues($event,child.vb_edit,child.id,'vb_edit','select',child.subname)" ></b-form-checkbox></td>
                                        
                                        <td><b-form-checkbox :disabled="selected_role_access=='fullaccess'" switch size="md" :checked="child.vb_view==1" @change="updateValues($event,child.vb_view,child.id,'vb_view','select',child.subname)" ></b-form-checkbox></td>
									</tr>
								</tbody>
							</table>
						</b-collapse>
					</div>
				</div>
				
				
			</b-col>
		</b-row>
		
		
		
		
		<b-modal id="modal-C"  size="lg" 
		
		ref="modal"
		title="Add New Role"
		header-bg-variant="primary"
		header-text-variant="light"
		
        hide-footer
		centered
		>
			<form ref="form">
				<b-form-group
				label="Name"
				label-for="name-input"
				>
					<b-input-group >
						<b-form-input
						v-model="role_name"
						id="name-input"
						placeholder="Enter New Role name"
						required
						></b-form-input>
						<b-input-group-append>
							<b-button class="btn btn-venuecolor" @click="handleOk()">Save</b-button>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</form>
			<div v-if="access_permission.length>1">
				Full Access  <b-form-checkbox switch size="md" name="view"   @change="selectAll()"  v-model="allSelected"  ></b-form-checkbox> 
			</div>
			<b-row>
				
				
				<b-col md="4"  v-for="(access,a) in access_permission" :key="a"  class="mb-3">
					<b-card header-tag="header" >
						<template #header>
							<div class="d-flex justify-content-between">
								<h6 class="mb-0">{{ access.name }}</h6>
								<b-form-checkbox switch size="md" name="view" ref='children' v-model="selected"   @change="selectHeaderAll(a)"  :checked="access.vb_view==1" :value="access.Mid"></b-form-checkbox>
							</div>
						</template>
						<b-list-group v-for="(submenu,a) in access.children" :key="a">
							<b-list-group-item >
								<div class="d-flex justify-content-between">
									<div>{{ submenu.subname }}</div>
									<div>
										<b-form-checkbox  switch size="md" v-model="userIds" @click="select" :value="submenu.id"  :checked="access.vb_view==1"></b-form-checkbox>
									</div>
								</div>
								
								
								
							</b-list-group-item>
						</b-list-group>
					</b-card>
				</b-col>
			</b-row>
			<div v-if="access_permission.length>1">
                <button class="btn btn-venuecolor btn-sm btn-sm mx-auto w-50"  @click="resetALlROle()" >Save & Close </button>
			</div>
		</b-modal>
		<b-modal id="modal-M" centered title="User " no-close-on-esc  no-close-on-backdrop header-bg-variant="primary" header-text-variant="light" hide-footer>
			
			
			<div class="">
				
				<b-row>
					<input type="hidden" id="" name="" v-model="form.id">
					<b-col cols="4" class="mt-3">
						<span class="notify-badge" @click="$refs.file.click()"><i class="mdi mdi-pencil"></i></span>
						<p><img class=" img-fluid img-custom-rounded" :src="form.logo ? backend_url+'/'+form.logo: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png' " alt="card image"></p>
						
						<input type="file" ref="file" style="display: none" />
						
					</b-col>
					<b-col cols="8" class="mt-3">
						<b-col cols="12">
							<input type="text" autocomplete="off" class="form-control venue-custom-input" :state="validateStateusers('names')"
							v-model="$v.form.names.$model" name="names" id="names" aria-describedby="helpId" placeholder="Enter User Full name" >
                            <span v-if="!this.$v.form.names.required" class="text-danger">
								Please enter name
							</span>
						</b-col>
						<b-col cols="12">
							<input type="email" autocomplete="off" class="form-control venue-custom-input"  v-model="$v.form.email.$model"
							name="email" id="" aria-describedby="helpId" placeholder="Enter Email Address"  :state="validateStateusers('email')" >
                            <span v-if="!this.$v.form.email.required" class="text-danger">
								Email is Required*
							</span>
                            <span v-if="this.$v.form.email.required && !this.$v.form.email.email" class="text-danger">
								Please enter Valid Email address
							</span>
							
						</b-col>
						<b-col cols="12">
							<input type="number" class="form-control venue-custom-input" 
							name="" id="mobile" autocomplete="off" aria-describedby="helpId" :state="validateStateusers('mobile')"  placeholder="Enter Mobile number" v-model="$v.form.mobile.$model" >
							<span v-if="!this.$v.form.mobile.required " class="text-danger">
								Please enter mobile number
							</span>
							<span v-if="this.$v.form.mobile.required && !this.$v.form.mobile.minLength" class="text-danger">
								Mobile number must be 10 characters long.
							</span>
							<span v-else-if="this.$v.form.mobile.required && !this.$v.form.mobile.maxLength " class="text-danger">
								Mobile number must be 12 characters long.
							</span>
						</b-col>
					</b-col> 
					<b-col cols="12" class="mt-3">
						<b-row>
							<b-col cols="6" v-if="passwordEdit">
								<input autocomplete="off" type="password" class="form-control venue-custom-input" v-model="form.password" name="" id="" aria-describedby="helpId" placeholder="Enter Password"  >
                                
								
							</b-col>
							<b-col cols="6" v-if="passwordEdit">
								<input autocomplete="off" type="password" class="form-control venue-custom-input"  v-model="form.confirm_password" name="" id="" aria-describedby="helpId" placeholder="Enter Confirm Password" >
							</b-col>
							
							<b-col cols="12" class="mt-2">
								<b-form-select class="form-control venue-custom-input"  :state="validateStateusers('role')"  v-model="$v.form.role.$model" :options="Roles"></b-form-select>
                                <span v-if="!this.$v.form.role.required " class="text-danger">
									Please Select Role
								</span>
							</b-col>
                            
							<b-col cols="12" class="mt-2">
								<div class="">
									<input type="checkbox" name="" id="only_section" v-model="form.section"> Particular session view 
								</div>
							</b-col>
						</b-row>	
					</b-col> 
				</b-row>
				<b-row class="mt-3">
					<b-col cols="9 " offset-md="3">
						<button class="btn btn-venuecolor btn-sm btn-sm mx-auto w-50"  @click="Save()" >Save  </button>
					</b-col>
				</b-row> 
			</div>
		</b-modal>
		
	</div>
</template>

<script>
	import axios from "axios";
	import { validationMixin } from "vuelidate";
	import {
		required ,email ,minLength ,maxLength
	} from "vuelidate/lib/validators";
	
	export default {
		components:
		{
		},
		mixins: [validationMixin],
		data() {
			return {
                show:false,
                editStatus:false,
                loadTeams:[],
                Modules:[],
                access_permission:[],
                Primary:'',
                selected_role:'',
                role_selected:'',
                selected_role_access:'',
                selected1: [],
                selected: [],
                Roles: [],
				allSelected: false,
				passwordEdit: true,
				userIds: [],
				All_modules: [],
				loaders: [],
				loaders_after: [],
				loaders1: false,
				loaders2: false,
                roles_selected:'',
				name_selected:'',
				per:
				{
					view:'',
					create:'',
					edit:'',
				},
                form: 
                {
					names:'',
					email:'',
					mobile:'',
					password:'',
					confirm_password:'',
					role:'',
					id:'',
					logo:'',
					section:false,
					address:'',
					city:'',
					pincode:'0',
					state:'',
					about:'',
				},
                role_name:'',
                role_id:'',
                backend_url:'',
				
			}
		},
		validations: {
			
			form:{
				names : { required },
				email:{ required,email },
				role:{ required },
                mobile: { required, minLength: minLength(10) , maxLength: maxLength(12) },
			},
			
			
		},
		methods:{
			validateStateusers(field) {
				const { $dirty, $error } = this.$v.form[field];
				return $dirty ? !$error : null;
			},
			back()
			{
				this.editStatus=false;
			}   ,
			create_teams()
			{
				this.$router.push("/teams/add_teams");
			},
			call_add()
			{
				this.form.names='';
				this.form.email='';
				this.form.mobile='';
				this.form.role='';
				this.form.id='';
				this.form.logo='';
				this.form.section= false;
				this.$root.$emit("bv::show::modal", "modal-M");
			},
			role_add()
			{
				this.$root.$emit("bv::show::modal", "modal-C");
			},
			validateState(name) {
				
				const { $dirty, $error } = this.$v.form[name];
				return $dirty ? !$error : null;
			},
            Save()
            {
                this.$v.form.$touch();
				console.log(!this.$v.form.$anyError);
				if (!this.$v.form.$anyError) {
					
					
					const formData= new FormData();
					let file = this.$refs.file.files[0];
					formData.append('image', file);
					formData.append('name', this.form.names);
					formData.append('email', this.form.email);
					formData.append('mobile', this.form.mobile);
					formData.append('password', this.form.password);
					formData.append('role', this.form.role);
					formData.append('id', this.form.id);
					formData.append('address', this.form.address);
					formData.append('city', this.form.city);
					formData.append('pincode', this.form.pincode);
					formData.append('state', this.form.state);
					formData.append('about', this.form.about);
					formData.append('section', this.form.section ==true ? 1 : 0 );
					
					axios.post("/admin/create_users",formData).then((resp) => {
						if (resp.data.status_code == 200) {
							if (resp.data.status) {
								this.$root.$emit("bv::hide::modal", "modal-M");
								this.$root.$refs.app.showToast("success", 'User added');
								this.load_teams();
								
							}
							else
							{
								this.$root.$refs.app.showToast("danger", resp.data.message);
							}
						}
					});
				}
			},
            load_teams()
            {
                axios.get("/admin/teams_view").then((resp) => {
					this.loadTeams = resp.data.All;
					this.Primary = resp.data.Primary;
					//this.Modules = resp.data.Modules;
                    this.role_access(resp.data.Primary.role_id,'fullaccess',resp.data.Primary.role_type,resp.data.Primary.name);
                    //Primary.name
				});
			},
            addtoPrimary(id)
            {
                this.$swal({
					title: 'Are you sure?',
					text: "Are you sure want to Update to Primary!",
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, Update it!'
					}).then((result) => {
					if (result.value) {
						
						axios.post('/api/update_access_primary',{id:id}).then(response => {
							response
							// this.results = response.data;
                            this.load_teams();
						})
						
					}
				});
			},
            editTeam(edit_id)
            {
                this.passwordEdit=false;
                this.$root.$emit("bv::show::modal", "modal-M");
				
                axios.post("/admin/edit_users",{ edit_id:edit_id}).then((resp) => 
                {
                    this.form.names=resp.data.name;
                    this.form.email=resp.data.email;
                    this.form.mobile=resp.data.phone;
                    this.form.role=resp.data.role_id;
                    this.form.id=resp.data.user_id;
                    this.form.logo=resp.data.logo;
                    this.form.section=resp.data.only_section ==1 ? true : false;
                    
				})
				
			},
            handleOk()
			{
                this.access_permission =[];
				axios.post("/admin/add_new_roles",{ role_name:this.role_name}).then((resp) => 
				{
                    if(resp.data.status) 
                    {
                        this.access_permission = resp.data.role_access;
                        this.role_id = resp.data.role;
					}
				})
			},
			
			selectAll() {
				this.userIds = [];
				this.selected = [];
				
				if (this.allSelected) {
					for(var m=0; m< this.access_permission.length;m++)
					{
						this.selected.push(this.access_permission[m].Mid);
						for(var n=0; n< this.access_permission[m].children.length;n++)
						{
							this.userIds.push(this.access_permission[m].children[n].id);
						}
					}
					
				}
				else
				{
					this.userIds = [];
				}
				this.save_permission_access(this.userIds,this.role_id);
				this.load_teams();
				this.load_roles();
			},
			selectHeaderAll(numb)
			{
				if(this.selected) 
				{
					for(var n=0; n< this.access_permission[numb].children.length;n++)
					{
						this.userIds.push(this.access_permission[numb].children[n].id);
					}
				}
				else
				{
					for(var l=0; l< this.access_permission[numb].children.length;l++)
					{
						this.userIds.splice(this.access_permission[numb].children[l].id,1);
					}
					
				}
				this.save_permission_access(this.userIds,this.role_id);
				
				
				
			},
			select: function() {
				this.allSelected = false;
			},
			save_permission_access(access,role_id)
			{
				axios.post("/api/save_permission_access",{ access:access,role_id:role_id}).then((resp) => 
				{
					resp
				})
			},
			load_roles()
			{
				axios.get("/admin/roles").then((resp) => 
				{
					
					for(var r=0; r< resp.data.roles.length;r++)
					{
						if(resp.data.roles[r].role_type!="admin" && resp.data.roles[r].role_type!="vendor" && resp.data.roles[r].role_type!="franchise")
						{
							this.Roles.push(
							{
								value:resp.data.roles[r].role_id,
								text:resp.data.roles[r].role_type,
							});
						}
						
					}
				})
			},
			role_access(role_id,role_type,role,name)
			{
				this.show=true;
				this.roles_selected=role;
				this.name_selected=name;
				this.selected_role=role_id;
				this.selected_role_access=role_type;
				/* axios.post("/api/Access_role_permission",{role:role_id}).then((resp) => {
					this.Modules = resp.data.Modules;
					//this.access_permission = resp.data.role_access;
				});  */
			axios.post("/admin/teams_permission",{role:role_id}).then((resp) => {
					this.show=false;
					this.All_modules = resp.data.modules;
				});
 
			},
			
			/* newrole_access(role_id)
			{
				this.selected_role=role_id;
				axios.post("/api/teams_permission",{role:role_id}).then((resp) => {
					this.All_modules = resp.data.modules;
				});
			}, */

			updateValues(event,status,id,type,select,subname)
			{
				axios.post("/api/access_permission_update",{ 
					id:id,
					status:status,
					type:type,
					select:select,
					subname:subname,
					Rid:this.selected_role,
				}).then((resp) => 
				{
					if(resp.data.status) {
						this.role_access(this.selected_role,'customaccess');
					}
				})
			},
            Refresh_role()
            {
                axios.post("/api/refresh_role").then((resp) => 
				{
                    resp;
                    this.load_teams();
                    this.load_roles();
				})
			},
            imagefileUpload()
			{
				const formData= new FormData();
				let file = this.$refs.file.files[0];
				formData.append('image', file);
				axios.post("/api/user_image_upload",formData ).then((resp) => {
                    this.form.logo=resp.data;
				});
				
			},
            resetALlROle()
            {
                this.$root.$emit("bv::hide::modal", "modal-C");
                this.access_permission=[];
                this.role_name="";
                this.$root.$refs.app.showToast("success", 'Role and Permission Created');
			},

			select_all_mod(e,v,id,vb_type,selectall,parent_name)
			{
				
				this.loaders[v]=true;
				this.loaders_after[v]=false;
				this.loaders1=true;
				//e,v,id,vb_type,selectall,parent_name
				axios.post("/api/update_access_permission_update",{ 
					id:id,
					status:e,
					type:vb_type,
					select:selectall,
					subname:parent_name,
					Rid:this.selected_role,
				}).then((resp) => 
				{
					this.loaders1=false;
					
					this.loaders[v]=false;
					setTimeout(() => {
						this.loaders2=true;
						this.loaders_after[v]=false;
						this.role_access(this.selected_role,'customaccess',this.roles_selected,this.name_selected);
				
					}, 1000);
					
					resp;
					//this.newrole_access(this.selected_role);
					
				})
			}

			
			
		},
		mounted()
		{
			this.backend_url=process.env.VUE_APP_APIURL;
			this.load_teams();
			this.load_roles();
			
		}
		//editTeam
	}
</script>

<style>
	.bg-pink
	{
    background-color: #e8d8f7;
	}
	.img-custom-rounded
	{
    height: 100px;
    width: 100px;
    border-radius: 50%
	}
	.notify-badge{
    position: absolute;
    right: 51px;
    top: 10px;
    background: #05341d;
    text-align: center;
    border-radius: 30px 30px 30px 30px;
    color: white;
    font-size: 10px;
    padding: 4px;
    width: 23px;
    cursor: pointer;
	}
	.padding-width td
	{
		padding: 0.25rem 1.2rem;
	}
	.table-width
	{
		width: 160px;
    text-align: left;
	}
	.table-widths
	{
		width: 172px;
    text-align: left;
	}
</style>